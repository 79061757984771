export const FETCH_BOARD_MEMBERS = "FETCH_BOARD_MEMBERS";
export const CREATE_BOARD_MEMBER = "CREATE_BOARD_MEMBER";
export const UPDATE_BOARD_MEMBER = "UPDATE_BOARD_MEMBER";
export const DELETE_BOARD_MEMBER = "DELETE_BOARD_MEMBER";
export const LISTEN_TO_SELECTED_BOARD_MEMBERS = "LISTEN_TO_SELECTED_BOARD_MEMBERS";
export const LISTEN_TO_BOARD_MEMBER_PHOTOS = "LISTEN_TO_BOARD_MEMBER_PHOTOS";
export const CLEAR_SELECTED_BOARD_MEMBER = "CLEAR_SELECTED_BOARD_MEMBER";
export const CLEAR_BOARD_MEMBERS = "CLEAR_BOARD_MEMBERS";
export const SET_FILTER = "SET_FILTER";
export const SET_DATE = "SET_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
